import { LIVE } from '../constants';

export type Environment = 'live' | 'stage' | 'dev' | 'sandbox' | 'local';

/*
 * All configuration interfaces should extend the BaseConfigOptions
 */
export interface BaseConfigOptions {
    environment?: Environment;
}

/*
 * Optional utility function that can be used in order to allow runtime overrides of specific properties for the caller, useful while developing
 */
export function withOverrides<T>(
    config: T,
    overrideConfig: Partial<T> | undefined,
    environment: Environment
): T {
    if (environment === LIVE || !overrideConfig) {
        return config;
    }

    return Object.freeze({ ...config, ...overrideConfig });
}
