import { Environment } from './utils';
import {
    DEVELOPMENT,
    DEVELOPMENT_URL,
    LIVE,
    LIVE_URL,
    SANDBOX,
    SANDBOX_URL,
    STAGE,
    STAGE_URL,
} from './constants';

export function getEnvironment(location = window.location): Environment {
    if (window && !!window.node_env_vars && !!window.node_env_vars.appEnv) {
        return window.node_env_vars.appEnv;
    }
    // https://developer.mozilla.org/en-US/docs/Web/API/Location/hostname
    // Opera doesn't support location.hostname, but does support location.host
    const hostname =
        location.hostname ||
        (location.host.includes(':')
            ? location.host.slice(0, location.host.indexOf(':'))
            : location.host);

    if (hostname === 'localhost' || hostname.startsWith('dev')) {
        return DEVELOPMENT;
    } else if (hostname.startsWith('stage')) {
        return STAGE;
    } else if (hostname.startsWith('sandbox')) {
        return SANDBOX;
    } else {
        /*
         * Production is perhaps the best default, since if it's wrong things would
         * would only break for devs and testers instead of customers.
         */
        return LIVE;
    }
}

export function getIdentityUrl(location: Location): string {
    switch (getEnvironment(location)) {
        case DEVELOPMENT:
            return DEVELOPMENT_URL;
        case STAGE:
            return STAGE_URL;
        case SANDBOX:
            return SANDBOX_URL;
        case LIVE:
            return LIVE_URL;
        default:
            throw Error(`Unknown environment ${getEnvironment(location)}`);
    }
}
