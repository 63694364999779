import { DEVELOPMENT, LIVE, LOCAL, SANDBOX, STAGE } from '../../constants';
import { BaseConfigOptions, Environment, withOverrides } from '../../utils';
import { config as dev } from './dev';
import { config as stage } from './stage';
import { config as live } from './live';
import { getEnvironment } from '../../env-getters';

export interface Config {
    AUTH_API_HOST: string;
    LD_CLIENT_ID: string;
}

/*
 * All configuration interfaces should extend the BaseConfigOptions
 */
export interface MypageAppConfigOptions extends BaseConfigOptions {
    overrideConfig?: Partial<Config>;
}

/*
 * Returns the specified configuration based on which environment.
 */
function getConfig(environment: Environment): Config {
    switch (environment) {
        case LOCAL:
        case SANDBOX:
        case DEVELOPMENT:
            return dev;
        case STAGE:
            return stage;
        case LIVE:
            return live;
        default:
            throw Error(`Unknown environment ${environment}`);
    }
}

/*
 * Showcasing a simple config getter
 *
 * export function getMypageAppConfigSimple(opts?: BaseConfigOptions): Config {
 *   return getConfig(opts?.environment ?? getEnvironment());
 * }
 */

/*
 * Example showcasing how manual overrides can be done at runtime
 */
export function getMypageAppConfig(opts?: MypageAppConfigOptions): Config {
    const env = opts?.environment ?? getEnvironment();
    const config = getConfig(env);
    return withOverrides(config, opts?.overrideConfig, env);
}
