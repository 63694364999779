export const LOCAL = 'local';
export const DEVELOPMENT = 'dev';
export const SANDBOX = 'sandbox';
export const STAGE = 'stage';
export const LIVE = 'live';

export const DEVELOPMENT_URL = 'https://dev.app.mercell.com';
export const SANDBOX_URL = 'https://sandbox.app.mercell.com';
export const STAGE_URL = 'https://stage.app.mercell.com';
export const LIVE_URL = 'https://live.app.mercell.com';
